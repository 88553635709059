import React, {FC, Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {Outlet} from "react-router-dom";
import {footerLinks} from "data/constants/navigation";
import {Footer} from "views/components/footer/footer.component";
import {ISecretLayoutController} from "./secret_layout.controller";
import {Header} from "views/components/header/header/header.component";
import {ReactComponent as LogoIcon} from "assets/img/nrlHub.svg";

export const SecretLayout: FC = observer(() => {
	const {subNavItems, burgerNavItems} = useViewController<ISecretLayoutController>(
		Bindings.LandingLayoutController
	);

	return (
		<Fragment>
			<Header
				LogoIcon={LogoIcon}
				subNavItems={subNavItems}
				burgerNavItems={burgerNavItems}
				isHideMobileMenu
				isHideSubNav></Header>

			<Outlet />
			<Footer links={footerLinks} />
		</Fragment>
	);
});
